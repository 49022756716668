import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAgHEoGfYhVogGP1ZnO5MduefKFSNVnYGM",
    authDomain: "edenred-eday-23.firebaseapp.com",
    projectId: "edenred-eday-23",
    storageBucket: "edenred-eday-23.appspot.com",
    messagingSenderId: "706388090600",
    appId: "1:706388090600:web:9515344bf68a0b8eba335d"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export {auth, firebase}